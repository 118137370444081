<template>
  <v-layout wrap>
    <v-flex xs8 style="padding: 20px 10px 20px 20px">
    <custom-table
      :loading="loading"
      :title="$t('sysUser.title', locale)"
      :headers="[
        { text: '', value: 'image', sortable: false, width: '40px' },
        { text: $t('sysUser.name', locale), value: 'ident' },
        { text: $t('sysUser.email', locale), value: 'Email' },
        { text: $t('sysUser.environments', locale), value: 'Environments' },
      ]"
      :onGetData="handleGetData"
      :onSearch="handleSearch"
      :tableData="users"
      :onEditItem="handleEdit"
      :onDeleteItem="handleDelete"
      :startIndex="startIndex"
      :itemsPerPage="itemsPerPage"
      :count="count"
      :hideAdd="true"
    />
    <!--custom-table
      :loading="loading"
      :title="$t('sysUser.title', locale)"
      :headers="[
        { text: '', value: 'image', sortable: false, width: '40px' },
        { text: $t('sysUser.name', locale), value: 'ident' },
        { text: $t('sysUser.email', locale), value: 'Email' },
        { text: $t('sysUser.environments', locale), value: 'Environments' },
        { text: '', value: 'actions', align: 'end' },
      ]"
      :onGetData="handleGetData"
      :onSearch="handleSearch"
      :tableData="users"
      :onEditItem="handleEdit"
      :onDeleteItem="handleDelete"
      :startIndex="startIndex"
      :itemsPerPage="itemsPerPage"
      :count="count"
    /-->
    </v-flex>
    <v-flex xs4 style="padding: 20px 20px 20px 10px">
      <v-card
        style="min-height:calc(100vh - 104px);"
        elekvation="0"
      >
        <div style="padding: 10px 20px;">
          <v-btn
            v-if="!clientDialog"
            color="primary"
            block
            @click="handleEdit(null)"
          >
            {{ $t('common.add', locale)}}
          </v-btn>
        </div>
        <user
          :editID="editID"
          :dialog="clientDialog" :onCloseDialog="() => {this.clientDialog = false}"
          :onGetData="handleGetData"
        />
        <info-dialog
          :show="showInfo"
          :onClose="() => { showInfo = false }"
        />
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import utilsService from '@/services/utils'
  import CustomTable from '@/components/customTable/Index'
  import User from './User'
  import InfoDialog from './InfoDialog'
  export default {
    components: {
      CustomTable,
      User,
      InfoDialog,
    },
    data: () => ({
      loading: false,
      search: '',
      clientDialog: false,
      editID: null,
      users: [],
      startIndex: 0,
      itemsPerPage: 5,
      count: null,
      showInfo: false,
    }),
    computed: {
      ...mapState('app',['locale']),
    },
    mounted () {
      this.handleGetData()
    },
    methods: {
      handleSearch (v) {
        this.search = v
        this.handleGetData(0)
      },
      handleGetData (startIndex) {
        this.loading = true
        if (startIndex >=0 ) this.startIndex = startIndex
        api.getAll ('system', 'v1/system/users/', this.startIndex, this.itemsPerPage, this.search)
        .then(response => {             
          this.count = response.count
          this.users = response.data.map (item => {
            item.image = item.Avatar
            item.Environments = item.Environments ? JSON.parse(item.Environments).join (', ') : ''
            return item
          })
          this.loading = false
        })
      },
      handleEdit (v) {
        if (v && utilsService.checkIsActualUser(v)) {
          api.put ('system', `v1/system/users/${v}/notification`)
            .then(() => { })        
          this.showInfo = true
          return
        }
        // if new [v] is null else [v] is userID
        this.editID = v
        this.clientDialog = true   
      },
      handleDelete (v) {
        if (v) 
          api.deleteItem ('system', 'v1/system/users/', v)
          .then(() => {                      
            this.handleGetData(this.startIndex)
          })
      }
    }
  }
</script>
